import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Menu } from "antd";
import { AppLayout } from "components/ContainerComponents/AppLayout";
import { Notification } from "components/ContainerComponents/Notification";
import { useDispatch } from "react-redux";
import {
  getPendencies,
  setHasOrderNotification,
} from "../../../store/slices/order";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const {
  HeaderContainer,
  HeaderContent,
  AppLogo,
  NavContainer,
  NavDropdown,
  HeaderDropdown,
} = AppLayout;

const { MuAlert } = Notification;

const AppHeader = ({ authUser, userSignOut, currentOrderData }) => {
  const dispatch = useDispatch();
  const matchOrderDetails = useRouteMatch("/crm/orders/:id");
  const showNotification = useSelector(
    (state) => state.order.hasOrderNotification
  );
  const documentNumber = currentOrderData?.initial?.documentNumber;
  const customerId = useSelector((state) => state?.order?.customerId);

  const redirectUrl = `/crm/customers/${customerId}?tab=currentPolicy`;

  const handleSignOut = () => {
    userSignOut();
  };
  const userMenuOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/change-password">Alterar senha</Link>
      </Menu.Item>
      <Menu.Item onClick={handleSignOut}>Sair</Menu.Item>
    </Menu>
  );

  const dashboardOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/">CRM</Link>
      </Menu.Item>
    </Menu>
  );

  const adminOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/management/users">Usuários</Link>
      </Menu.Item>
    </Menu>
  );

  const crmdOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/crm/customers">Clientes</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/crm/orders">Pedidos de Cotação</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/crm/client-app">Portal do Cliente</Link>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    dispatch(setHasOrderNotification(false));
    if (documentNumber) {
      dispatch(getPendencies(documentNumber));
    }
  }, [documentNumber]);

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <AppLogo
            src={require("assets/images/mutuus-logo.png")}
            alt="Mutuus Seguros - logomarca"
          />
          {matchOrderDetails?.isExact && showNotification ? (
            <MuAlert
              message="Este cliente possui faturas em aberto"
              type="error"
              redirectUrl={redirectUrl}
            />
          ) : null}
          <HeaderDropdown menu={userMenuOptions}>
            {authUser?.name ? authUser?.name : "Minha Conta"}
          </HeaderDropdown>
        </HeaderContent>
      </HeaderContainer>
      <NavContainer>
        <NavDropdown menu={dashboardOptions}>Dashboard</NavDropdown>
        <NavDropdown menu={adminOptions}>Administrativo</NavDropdown>
        <NavDropdown menu={crmdOptions}>CRM</NavDropdown>
      </NavContainer>
    </>
  );
};

export default AppHeader;
